<!--  -->
<template>
  <div>
    <van-sticky>
      <!-- 盘点类型 -->
      <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <!-- <div class="point_kind">{{ pointTitle.inventoryTypeName }}</div> -->
        <div class="point_kind">修改出库信息</div>
        <div class="point_point"></div>
      </div>
    </van-sticky>
    <!-- 单据日期    入库类型  包装规格  供应商 -->
    <div class="point_main">
      <!-- 单据日期 -->
      <div class="every_option">
        <div style="display: flex">
          <div style="font-size: 4vw">单据日期：</div>
        </div>
        <div
          style="width: 57vw; height: 100%; display: flex; align-items: center"
        >
          <!-- <div style="font-size:3.4vw;margin-left:7vw;" >{{query.startTime}}</div> -->
          <el-input
            v-model="dateVaule1"
            size="small"
            :disabled="true"
          ></el-input>
        </div>
      </div>
      <!-- 日期选择 -->
      <van-popup position="bottom">
        <van-datetime-picker type="date" title="选择年月日" />
      </van-popup>

      <!-- 单据类型 -->
      <div style="display: flex; align-items: center">
        <div style="font-size: 4vw">入库类型：</div>
        <el-select
          v-model="value1"
          size="small"
          placeholder="请选择"
          style="width: 57vw"
          :disabled="true"
        >
          <el-option
            v-for="item in throughtList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 包装类型 -->
      <div style="display: flex; align-items: center">
        <div style="font-size: 4vw">包装规格：</div>
        <el-select
          v-model="pack1"
          size="small"
          placeholder="请选择"
          style="width: 57vw"
          :disabled="true"
        >
          <el-option
            v-for="item in packList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 供应商选择 -->
      <div>
        <div style="display: flex; align-items: center; margin-left: 5vw">
          <div style="font-size: 4vw">供应商：</div>
          <el-input
            :disabled="true"
            v-model="state1"
            style="width: 57vw"
            size="small"
          ></el-input>
        </div>
      </div>
    </div>
    <!-- 输入商品货号  输入药品监码  或者监码扫描 -->
    <!-- <div style="display: flex; justify-content: space-around">
        <el-input
          size="small"
          v-model="goodsVaule"
          placeholder="请输入商品货号"
          style="width: 60vw"
        >
        </el-input>
        <el-button
          type="primary"
          style="
            height: 8.5vw;
            display: flex;
            justify-content: space-around;
            align-items: center;
          "
          @click="goodsQuery1()"
          >商品查询</el-button
        >
      </div> -->
    <!-- 药品搜索 -->
    <div
      style="
        width: 96%;
        height: 9vw;
        border-radius: 4.5vw;
        display: flex;
        align-items: center;
        background: #fff;
        color: black;
        margin-left: 2%;
        margin-top: 2vw;
        border: 1px solid rgba(190, 190, 190, 0.4);
      "
    >
      <img
        src="../assets/add/sousuo 拷贝.png"
        alt=""
        style="width: 20px; height: 20px; margin-left: 4vw"
      />
      <input
        type="text"
        placeholder="药品搜索"
        v-model="goodsVaule"
        style="
          margin-left: 2vw;
          border: 0;
          background-color: #fff;
          width: 80%;
          height: 8vw;
        "
      />
      <div style="margin-left: 3vw">
        <div
          @click="showPopup(goodsVaule)"
          style="
            width: 18vw;
            height: 8.5vw;
            border-radius: 0 4.5vw 4.5vw 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 5vw;
          "
        >
          搜索
        </div>

        <van-action-sheet
          v-model="show1"
          position="bottom"
          cancel-text="取消"
          close-on-click-action
        >
          <ul
            v-for="(item, index) in goodsList2"
            :key="index"
            style="font-size: 5vw; padding: 3vw"
          >
            <li
              @click="onSelect(item)"
              style="border-bottom: 1px solid #e7e8e8"
            >
              <span>{{ index + 1 }}.</span>
              {{ item.goodsNo }}/{{ item.goodsName }}/{{
                item.specification
              }}/{{ item.production_unit }}
            </li>
          </ul>
        </van-action-sheet>
      </div>
    </div>
    <!-- 监码扫描 手动输入 -->
    <div style="display: flex; justify-content: space-around; margin-top: 3vw">
      <el-input
        size="small"
        v-model="goodsVauleMed1"
        placeholder="请输入药品监码"
        style="width: 60vw"
        @focus="aaa()"
      >
      </el-input>
      <el-button
        v-if="jianma"
        type="primary"
        style="
          height: 8.5vw;
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
        @click="scanBianma1()"
        >监码扫描</el-button
      >
      <el-button
        v-if="shoudong"
        type="primary"
        style="
          height: 8.5vw;
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
        @click="gooodsScan2(goodsVauleMed1)"
        >手动输入</el-button
      >
    </div>

    <el-divider></el-divider>
    <div>
      <div
        style="
          width: 98%;
          height: 10vw;
          background: #4bc4fa;
          margin-left: 1%;
          margin-top: 2vw;
          border-radius: 2vw 2vw 0 0;
          display: flex;
          justify-content: space-around;
          align-items: center;
          color: #fff;
        "
      >
        <div>货号</div>
        <div>名称</div>
        <div>药品监码</div>
        <div>数量</div>
        <div>操作</div>
      </div>
      <div
        v-for="(item, index) in editDetails"
        :key="index"
        style="
          display: flex;
          width: 98%;
          height: 10vw;
          margin-left: 1%;
          color: #000;
          background: rgba(190, 190, 190, 0.2);
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div style="width: 15vw; margin-left: 4vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.itemNo }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 10vw">
              {{ item.itemNo }}
            </div>
          </el-tooltip>
        </div>
        <div style="width: 15vw; margin-right: 5vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.itemName }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 11vw">
              {{ item.itemName }}
            </div>
          </el-tooltip>
        </div>
        <div style="width: 15vw; margin-right: 11vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.traceCode }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 14.5vw">
              {{ item.traceCode }}
            </div>
          </el-tooltip>
        </div>
        <div style="width: 15vw; margin-right: 2vw">
          <el-tooltip placement="top">
            <div slot="content">{{ item.actualQuantity }}</div>
            <div style="overflow: hidden; white-space: nowrap; width: 13vw">
              {{ item.actualQuantity }}
            </div>
          </el-tooltip>
        </div>
        <div
          style="color: red; margin-right: 2vw; width: 10vw"
          @click="delList(index)"
        >
          删除
        </div>
      </div>
      <div
        style="display: flex; justify-content: space-around; margin-top: 2vw"
      >
        <el-button @click="dialogVisibleEnter()">保存</el-button>
      </div>
    </div>
    <!-- <div @click="goodsScan11(222222222)">安安</div> -->
  </div>
</template>
  
  <script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      packList: [
        { id: "001", name: "大包装" },
        { id: "002", name: "中包装" },
        { id: "003", name: "小包装" },
      ],
      throughtList: [
        { id: 101, name: "生产入库" },
        { id: 102, name: "采购入库" },
        { id: 103, name: "退货入库" },
        { id: 104, name: "调拨入库" },
        { id: 106, name: "零头入库" },
        { id: 107, name: "供应入库" },
        { id: 108, name: "召回入库" },
        { id: 110, name: "赠品入库" },
        { id: 111, name: "盘盈入库" },
        { id: 112, name: "报废入库" },
        { id: 113, name: "其他入库" },
      ],
      saveListScan: {},
      ItemList: [],
      goodsVauleMed: "", //输入的药品药监码
      detailSubList: [],
      dialogVisible: false,
      editDetails: [],
      dateVaule1: "",
      state1: "",
      state2: "",
      state3: "",
      state4: "",
      value1: "",
      pack1: "",
      objModify: {},
      goodsVaule: "",
      goodsVauleMed1: "",
      barTwo: "",
      goodsE: {},
      codeList: [],
      codeList1: [],
      shuzuEditChuku: [],
      shuzu2: [],
      state5: "",
      objModify: {},
      goodsList2: [],
      show1: false,
      jianma: true,
      shoudong: false,
      editChukuCode: "",
    };
  },
  mounted() {
    // this.goodsVaule = localStorage.getItem("xiugai");
    if (this.$route.params.barCodeOutScanChuku == "undefined") {
      this.edit(localStorage.getItem("idChuku"));
      console.log("aaaaaa");
    }
    this.value1 = localStorage.getItem("type1")
    this.dateVaule1=localStorage.getItem("data1")
    this.state1=localStorage.getItem("userName1")

    if (JSON.parse(localStorage.getItem("modifyChuku")) != null) {
      this.shuzuEditChuku = JSON.parse(localStorage.getItem("modifyChuku"));
    } else {
      this.shuzuEditChuku = [];
    }

    this.pack1 = "小包装";
    // alert(this.$route.params.barCodeOutScanTwo)
    // console.log(this.$route.params.TwoScan,"cccc")
    if (this.$route.params.barCodeOutScanChuku != "undefined") {
      console.log("aaaaa");
      this.editChukuCode = this.$route.params.barCodeOutScanChuku;
      this.goodsScan11(this.editChukuCode);
    }
  },
  methods: {
    aaa() {
      this.jianma = false;
      this.shoudong = true;
    },
    // 选择商品
    onSelect(item) {
      this.b = item.goodsNo + "/" + item.goodsName;
      localStorage.setItem("xiugai", this.b);
      this.goodsVaule = localStorage.getItem("xiugai");
      this.medicDetails = item;
      this.$delete(this.medicDetails, "approvalNumber");
      this.$delete(this.medicDetails, "unit");
      this.$delete(this.medicDetails, "specification");
      this.$delete(this.medicDetails, "retailPrice");
      this.$delete(this.medicDetails, "production_unit");
      this.medicDetails.itemNo = this.medicDetails.goodsNo;
      this.medicDetails.itemName = this.medicDetails.goodsName;
      this.medicDetails.id = 0;
      this.$delete(this.medicDetails, "goodsNo");
      this.$delete(this.medicDetails, "goodsName");
      localStorage.setItem("goodsDetail", JSON.stringify(this.medicDetails));
      this.show1 = false;
      this.medicDetailsShow = true;
    },
    // 药品搜索触发的事件
    showPopup(valueMedcine) {
      console.log(valueMedcine);
      if (valueMedcine == "") {
        this.$toast({ message: "请输入商品名或货号", duration: 1500 });
        return false;
      }
      this.$axios
        .get(this.$api.searchGoods2DetailList, {
          params: {
            keyWord: valueMedcine,
          },
        })
        .then((res) => {
          this.goodsList2 = res.data.data;
          this.goodsListShow = res.data.data;
        });
      this.show1 = true;
      this.informatiobShow = false;
    },
    // 修改保存接口
    dialogVisibleEnter() {
      //   console.log(this.dateVaule1, "要修改的日期时间");
      //   console.log(this.value1, "要修改的入库类型");
      //   console.log(this.state2, "2222222");
      //   console.log(this.state3, "33333333");
      //   console.log(this.state4, "44444444");
      // console.log(localStorage.getItem("must"), "55555");
      // console.log(this.editDetails, "数据");

      this.objModify.id = localStorage.getItem("must");
      this.objModify.inoutbillItemList = this.editDetails;

      // console.log(this.objModify, "总的修改");
      this.$axios
        .post(this.$api.updateInBillList, this.objModify)
        .then((res) => {
          // console.log(res, "a");
          if (res.data.code == 0) {
            this.$toast({
              message: "保存成功",
              duration: 1000,
            });
            // localStorage.removeItem("modify");
            localStorage.removeItem("xiugai");
            localStorage.removeItem("goodsDetail");

            // localStorage.removeItem("refName");
            // localStorage.removeItem("goodsDetail");
            // location.reload();
          } else {
            this.$toast({
              message: res.data.msg,
              duration: 2000,
            });
            return false;
          }
          // location. reload()
        });
    },
    edit(id) {
      this.$axios.post(this.$api.detailSubList + "/" + id).then((res) => {
        // console.log(res, "出库列表详情");
        this.editDetails = res.data.data.inoutbillItemList;
        this.editDetails.forEach((element) => {
          element.id = element.iid;
          this.$delete(element, "iid");
          this.$delete(element, "bookQuantity");
          this.$delete(element, "differenceQuantity");
          this.$delete(element, "updateTime");
        });

        localStorage.setItem("modifyChuku", JSON.stringify(this.editDetails));

        this.dateVaule1 = res.data.data.billTime;
        this.state1 = res.data.data.refUserName;
        this.state2 = res.data.data.refUserId;
        this.state3 = res.data.data.refUserName;
        this.state4 = res.data.data.physicType;
        this.value1 = res.data.data.billType;
        this.state5 = res.data.data.id;
        localStorage.setItem("type1",this.value1)
        localStorage.setItem("data1",this.dateVaule1)
        localStorage.setItem("userName1",this.state1)
        localStorage.setItem("must", this.state5);
      });
    },
    // 商品查询
    goodsQuery1() {
      if (this.goodsVaule == "") {
        this.$toast({
          message: "请输入商品货号",
          duration: 1000,
        });
        return false;
      }
      this.user_info = JSON.parse(localStorage.getItem("user_info"));
      this.$axios
        .get(this.$api.goodsStatisticsList, {
          params: {
            keyWord: this.goodsVaule,
            shopId: this.user_info.shopId,
          },
        })
        .then((res) => {
          this.goodsList = res.data.resultList[0];
          // console.log(this.goodsList, "完整的商品详情");
          this.$delete(this.goodsList, "approval_number");
          this.$delete(this.goodsList, "origin");
          this.$delete(this.goodsList, "unit");
          this.$delete(this.goodsList, "title");
          this.$delete(this.goodsList, "specification");

          this.goodsList.id = 0;
          // console.log(this.goodsList, "aaaaaaaaaa");
          localStorage.setItem("goodsDetail", JSON.stringify(this.goodsList));
        });
    },
    // 手动输入药品监码
    gooodsScan2(code) {
      this.jianma = true;
      this.shoudong = false;
      this.shuzuEditChuku = JSON.parse(localStorage.getItem("modifyChuku"));
      // console.log(this.shuzuEditChuku, "自带数据");
      // console.log(code);
      this.goodsE = JSON.parse(localStorage.getItem("goodsDetail"));
      // console.log(this.goodsE, "aaaaaa");
      this.goodsE.traceCode = code;
      this.goodsE.actualQuantity = 1;
      this.codeList.push(this.goodsE);
      this.editDetails = [...this.shuzuEditChuku, ...this.codeList];
      localStorage.setItem("modifyChuku", JSON.stringify(this.editDetails));
      this.codeList = [];
    },
    // 删除
    delList(index) {
      this.editDetails.splice(index, 1);
      localStorage.setItem("modifyChuku", JSON.stringify(this.editDetails));
      // console.log(this.editDetails, "a");
    },
    // 监码扫描
    scanBianma1() {
      wx.miniProgram.navigateTo({
        url: `/pages/outScaningTwo/outScaningTwo?token=${localStorage.getItem(
          "token"
        )}&id=${localStorage.getItem("index")}`,
      });
    },
    // 药监码扫描
    goodsScan11(code) {
      // this.shuzuEditChuku = JSON.parse(localStorage.getItem("modifyChuku"));
      this.goodsE = JSON.parse(localStorage.getItem("goodsDetail"));
      // console.log(this.goodsE, "商品信息");
      this.goodsE.traceCode = code;
      this.goodsE.actualQuantity = 1;
      this.codeList1.push(this.goodsE);
      this.editDetails = [...this.shuzuEditChuku, ...this.codeList1];
      localStorage.setItem("modifyChuku", JSON.stringify(this.editDetails));
      this.codeList1 = [];
    },
    back() {
    //   localStorage.removeItem("modify");
      localStorage.removeItem("xiugai");
      localStorage.removeItem("goodsDetail");
      this.$router.push("/point");
    },
  },
};
</script>
  <style scoped>
.point_main {
  width: 90%;
  height: 50vw;
  border-radius: 1vw;
  margin-left: 2%;
  margin-top: 1vw;
  padding: 3vw;
  /* background: url(../assets/icon/矩形\ 4\ 拷贝\ 13.png); */
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.every_option {
  /* height: 7vh; */
  display: flex;

  align-items: center;
}
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight: 600;
}
</style>